<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1" v-if="navigete!=null && navigete!='' && navigete!='null'">
        แจ้งรับสินค้า <v-icon>mdi-chevron-right</v-icon>
        {{ navigete[0]['title'] }}
      </v-card-title>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        รายการสินค้าพร้อมส่ง FlASH
      </v-card-title>
      <v-card-text align="center">
        <table width="60%"  class="packhai-border-table">
          <tr>
            <td>
              <v-row align="center" justify="center" class="my-5">
                <v-col cols="4"><b>สาขา</b></v-col>
                <v-col cols="4">{{ this.BranchName }}</v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td>
              <v-row align="center" justify="center" class="my-5">
                <v-col cols="4"><b>ที่อยู่</b></v-col>
                <v-col cols="4">{{ this.BranchAddress }}</v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td>
              <v-row align="center" justify="center" class="my-5">
                <v-col cols="4"><b>จำนวนออเดอร์</b></v-col>
                <v-col cols="4">{{ this.OrderCountFlash }}</v-col>
              </v-row>
            </td>
          </tr>
        </table>
        <v-row align="center" justify="center" class="my-5">
          <v-col cols="8">
            <v-btn @click="getDataFlash()" color="info" class="mx-3">ค้นหาข้อมูล</v-btn>
            <v-btn @click="updateNotify()" color="success" class="mx-3">ส่ง FLASH</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        รายการสินค้าพร้อมส่ง BEST
      </v-card-title>
      <v-card-text align="center">
        <table width="60%"  class="packhai-border-table">
          <tr>
            <td>
              <v-row align="center" justify="center" class="my-5">
                <v-col cols="4"><b>สาขา</b></v-col>
                <v-col cols="4">{{ this.BranchName }}</v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td>
              <v-row align="center" justify="center" class="my-5">
                <v-col cols="4"><b>ที่อยู่</b></v-col>
                <v-col cols="4">{{ this.BranchAddress }}</v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td>
              <v-row align="center" justify="center" class="my-5">
                <v-col cols="4"><b>จำนวนออเดอร์</b></v-col>
                <v-col cols="4">{{ this.OrderCountBest }}</v-col>
              </v-row>
            </td>
          </tr>
        </table>
        <v-row align="center" justify="center" class="my-5">
          <v-col cols="8">
            <v-btn @click="getDataBest()" color="info" class="mx-3">ค้นหาข้อมูล</v-btn>
            <!-- <v-btn @click="updateNotify()" color="success" class="mx-3">ส่ง FLASH</v-btn> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        รายการสินค้าพร้อมส่ง NIM
      </v-card-title>
      <v-card-text align="center">
        <table width="60%"  class="packhai-border-table">
          <tr>
            <td>
              <v-row align="center" justify="center" class="my-5">
                <v-col cols="4"><b>สาขา</b></v-col>
                <v-col cols="4">{{ this.BranchName }}</v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td>
              <v-row align="center" justify="center" class="my-5">
                <v-col cols="4"><b>ที่อยู่</b></v-col>
                <v-col cols="4">{{ this.BranchAddress }}</v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td>
              <v-row align="center" justify="center" class="my-5">
                <v-col cols="4"><b>จำนวนออเดอร์</b></v-col>
                <v-col cols="4">{{ this.OrderCountNim }}</v-col>
              </v-row>
            </td>
          </tr>
        </table>
        <v-row align="center" justify="center" class="my-5">
          <v-col cols="8">
            <v-btn @click="getDataNim()" color="info" class="mx-3">ค้นหาข้อมูล</v-btn>
            <!-- <v-btn @click="updateNotify()" color="success" class="mx-3">ส่ง FLASH</v-btn> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { shippingService } from '@/website/global'
  import { AlertSuccess, AlertWarning } from '@/website/global_alert'
  export default {
    components: {
      Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,

      navigete: [],

      BranchName: null,
      BranchAddress: null,
      OrderCountFlash: 0,
      OrderCountBest: 0,
      OrderCountNim: 0,
      orderList: [],
      Branch_data: [],


    }),
    async created() {
      document.title = this.$router.history.current.meta.title
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      await this.getDataBranch()
      await this.getDataFlash()
      await this.getDataBest()
      await this.getDataNim()
      this.page_loading = false
    },
    methods: {
      async getDataFlash () {
        this.loading = true
        let response = await axios.post(shippingService+'flash/get_notify_flash', {
          'BranchID': localStorage.getItem('Branch_BranchID')
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status == 'Success') {
          this.orderList = response.data.result[0]['orderList']
          this.BranchName = response.data.result[0]['Name']
          this.BranchAddress = response.data.result[0]['fullAddress']
          this.OrderCountFlash = response.data.result[0]['orderCount']
        }
        this.loading = false
      },

      async getDataBest () {
        this.loading = true
        let response = await axios.post(shippingService+'flash/get_notify_best', {
          'BranchID': localStorage.getItem('Branch_BranchID')
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status == 'Success') {
          if(response.data.result.length > 0){
            this.OrderCountBest = response.data.result[0]['orderCount']
          }else {
            this.OrderCountBest = 0
          }
        }
        this.loading = false
      },

      async getDataNim () {
        this.loading = true
        let response = await axios.post(shippingService+'flash/get_notify_nim', {
          'BranchID': localStorage.getItem('Branch_BranchID')
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status == 'Success') {
          if(response.data.result.length > 0){
            this.OrderCountNim = response.data.result[0]['orderCount']
          }else {
            this.OrderCountNim = 0
          }
        }
        this.loading = false
      },

      async getDataBranch () {
        this.loading = true
        let response = await axios.post(shippingService+'flash/get_data_branch_flash', {
          'BranchID': localStorage.getItem('Branch_BranchID')
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status == 'Success') {
          if(response.data.result.length > 0){
            this.Branch_data = response.data.result
          }else {
            this.Branch_data = []
          }
        }
        this.loading = false
      },

      async updateNotify () {
        if(this.orderList.length == 0) {
          // alert('ไม่มี order !!!')
          return
        }

        var mchId = null
        var nonceStr = String(Math.floor(Date.now() / 1000))
        var sign = null
        var srcName = this.Branch_data[0]['BranchName']
        var srcPhone = this.Branch_data[0]['branchPhone']
        var srcProvinceName = this.Branch_data[0]['Province']
        var srcCityName = this.Branch_data[0]['District']
        var srcDistrictName = this.Branch_data[0]['SubDistrict']
        var srcDetailAddress = this.Branch_data[0]['AddressBranch']
        var srcPostalCode = this.Branch_data[0]['Postcode']

        this.branch_data = {
            'mchId': mchId,
            'nonceStr': nonceStr,
            'sign': sign,
            'srcName': srcName,
            'srcPhone': srcPhone,
            'srcProvinceName': srcProvinceName,
            'srcCityName': srcCityName,
            'srcDistrictName': srcDistrictName,
            'srcDetailAddress': srcDetailAddress,
            'srcPostalCode': srcPostalCode,
        }

        this.loading = true
        let response = await axios.post(shippingService+'flash/update_notify_flash', {
          'BranchID': localStorage.getItem('Branch_BranchID'),
          'branch_data': this.branch_data,
          'orderList': this.orderList,

        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status == 'Success') {

          if(response.data.api_status == '1'){
              this.AlertSuccess()
          }else {
            if(response.data.result['message']=='คุณได้ทำรายนัดรับพัสดุแล้ว ไม่สามารถส่งรายการซ้ำได้'){
              this.AlertWarning("คุณได้ทำรายนัดรับพัสดุแล้ว ไม่สามารถส่งรายการซ้ำได้")
            } else {
              this.AlertWarning("ไม่สามารถทำรายการได้ api false")
            }

          }
        }
        this.loading = false
      },
      AlertSuccess,
      AlertWarning,
    },
  }
</script>

<style scoped>
  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    border-collapse: collapse;
    border: 1px solid #e9edf4;
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
    border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }
</style>
